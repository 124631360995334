// React Hooks
import React, {useState, useEffect} from 'react'
import {useLocation, Link} from 'react-router-dom'

// Bootstrap components
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

// Components
import Logo from '../Images/logo.png'
import { Login } from '../Pages/Login';

// React icons
import {RiLogoutBoxFill} from 'react-icons/ri'
import {FaUser} from 'react-icons/fa'
import {AiOutlineAppstoreAdd} from 'react-icons/ai';
import {BsBook} from 'react-icons/bs';

const clearLocalStorage = () => {
  window.localStorage.clear();
}

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  useEffect(() => {
      if (location.pathname === '/' || location.pathname === '/student-registration') {
          setShowHeader(false);
      } else {
          setShowHeader(true);
      }
  }, [location.pathname]);

  if (!showHeader) return null;

  return (
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{padding: 0}}>
      <Container>
        <Navbar.Brand href="/"><img src={Logo} width="120px" style={{verticalAlign: "middle"}} alt="Logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link to="/add-courses" style={{textDecoration:'none'}}><AiOutlineAppstoreAdd/> Add Courses &nbsp;</Link> 
              <Link to="/my-courses" style={{textDecoration:'none'}}><BsBook/> My Courses</Link>           
            </Nav>
          <Nav>
              <Link to="/profile" style={{textDecoration:'none'}}><FaUser/> Profile &nbsp;</Link> 
              <Link to="/" style={{textDecoration:'none'}} onClick={clearLocalStorage}><RiLogoutBoxFill/> Logout</Link>           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header;