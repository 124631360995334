// React and Libraries
import React from "react";
import Axios from "axios"

// React Hooks
import {useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";

// Bootstrap components
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

// Other components
import Logo from '../Images/logo.png'


export const Login = () => {

    const navigate = useNavigate();
    const[studentEmail, setStudentEmail] = useState('');
    const[password, setPassword] = useState('');



    const showSuccessMessage = () => {
      var successMessage = document.createElement("div");
      successMessage.innerHTML = "Please wait a few seconds while you are redirected to your profile <BR> <Spinner animation='border' role='status'><span className='visually-hidden'>Loading...</span></Spinner>"
      successMessage.style.backgroundColor = "#ede3a2";
      successMessage.style.fontSize = "14px"; 
      successMessage.style.color = "black";
      successMessage.style.padding = "20px";
      successMessage.style.width = "500px";
      successMessage.style.margin = "20px auto";
      document.getElementById("loginTitle").appendChild(successMessage)
    }

    const showFailureMessage = () => {
      document.getElementById("loginTitle").removeChild();
      var failureMessage = document.createElement("div");
      failureMessage.innerHTML = "User not found. Please try again or register as a new Student.";
      failureMessage.style.backgroundColor = "#dd0612";
      failureMessage.style.fontSize = "14px"; 
      failureMessage.style.color = "white";
      failureMessage.style.padding = "20px";
      failureMessage.style.width = "500px";
      failureMessage.style.margin = "20px auto";
      document.getElementById("loginTitle").appendChild(failureMessage)
      setTimeout(function() {
        document.getElementById("loginTitle").removeChild(failureMessage)
      }, 5000)
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(studentEmail);
        showSuccessMessage();
        try {
          const res = await Axios.post(`${process.env.REACT_APP_API_URL}/api/studentinfo`, {
            Email: studentEmail,
            Password: password
          });
          console.log(res);
          if (res.data !== '') {
            console.log("User authenticated");
            console.log(res.data);
            setStudentEmail(studentEmail);
            window.localStorage.setItem('SET_USERNAME', studentEmail);
            navigate("/profile")
          } else if(res.data === '') {
            showFailureMessage();
            alert("This user does not exist in our DB");
          }
        } catch (err) {
          console.log(err);
        }
      };

    useEffect(() => {
        console.log("Email and/or password changed")
        const btnSubmit = document.getElementById("buttonSubmit")
        btnSubmit.addEventListener("click", handleSubmit)

        return () => {
            btnSubmit.removeEventListener("click", handleSubmit)
        }
        
    }, [studentEmail, password])

    return(
        <div className="auth-form-container">
            <div style={{margin: "auto", padding: "auto"}}>
                <img src={Logo} width="250px" style={{verticalAlign: "middle"}} alt="Logo"></img>
            </div>
                <h2 id="loginTitle">Login</h2>
                
            <Form style={{width:"80%", border:"1px solid #E5E7E9", padding: "20px", margin: "auto"}}>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control value={studentEmail} onChange={(e) => setStudentEmail(e.target.value)} type="email" placeholder="Try 'test' both for email and password" id="email" name="email"/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="***********" id="password" name="password" />
                </Form.Group>

                <Button variant="primary" type="submit" id="buttonSubmit">Sign in</Button>
                <br></br>
                <Form.Text className="text-primary">
                <Link to="./student-registration" style={{textDecoration:'none'}}>Don't have an account? Register here.</Link> 
                </Form.Text>
            </Form>
       </div>
    )
}